/* eslint-disable no-unused-vars */
import React from 'react'
import Plaatjie from '@ubo/plaatjie'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'

// Slider
import Slider from 'react-slick'
import '../../../node_modules/slick-carousel/slick/slick.css'
import '../../../node_modules/slick-carousel/slick/slick-theme.css'

const Slide = styled.div`
  @media (min-width: 992px) {
    border-right: 1px solid ${(props) => props.theme.color.face.light};
  }
`

const SliderWrapper = styled.div`
  background-color: ${(props) => props.theme.color.face.main};
`

const StyledImageBlock = styled(Plaatjie)`
  filter: grayscale(91%) invert(13%);
  min-height: 100px;
  min-width: 100px;
  max-width: 140px;
  max-height: 120px;

  & img {
    object-fit: contain !important;
  }

  @media (min-width: 576px) {
    min-height: 100px;
    min-width: 100px;
    max-width: 140px;
    max-height: 120px;
  }
`

const SectionWrapper = styled.section`
  background-color: ${(props) => props.theme.color.face.secondary};
`

const Content = styled(ParseContent)`
  color: ${(props) => props.theme.color.face.light};
`

interface ClientsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Clients
}

const ClientsSlider: React.FC<ClientsProps> = ({ fields }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    accessibility: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <SectionWrapper className="py-2">
      <SliderWrapper>
        <div className="container pb-3 pb-md-5 py-lg-5 pb-lg-0">
          <div className="row">
            <div className="d-flex align-items-center col-lg-2">
              <Content
                content={fields.description}
                className="mx-auto mx-lg-0 my-lg-0 my-md-5 my-3"
              />
            </div>
            <div className="col-lg-10 pl-lg-5">
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <Slider {...settings}>
                {fields?.clients?.map((edge: any, index: number) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Slide className="d-flex justify-content-center" key={index}>
                    <StyledImageBlock image={edge.image} alt="" />
                  </Slide>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </SliderWrapper>
    </SectionWrapper>
  )
}

export default ClientsSlider
